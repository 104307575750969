(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
            $window = $(window),
            $doc = $(document),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables


        console.log("%c Developed by: Kutia Digital Agency http://kutia.net", "padding:9px 5px; background:#3c4952; line-height:25px;color: #59bf9d");
    });
}(window.jQuery, window, document));
